module.exports = function uniqueInformationValidator(data) {
  if (data.length > 100) {
    return {
      $valid: false,
      $message: 'Too many words!',
      extraParams: {},
    }
  }
  return {
    $valid: true,
    $message: '',
  }
}
