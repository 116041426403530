module.exports = function maxLengthValidator(data) {
  if (!data) {
    return {
      $valid: true,
      //$message: 'This field is required!',
      $message: '',
      extraParams: {},
    }
  }
  return {
    $valid: true,
    $message: '',
  }
}
