module.exports = function defendantsValidator(data) {
  const isEmpty = data.filter((item) => {
    if (
      item.first_name === '' ||
      item.last_name === '' ||
      item.phone === '' ||
      item.email === ''
    )
      return item
  })

  if (isEmpty.length > 0) {
    return {
      $valid: false,
      $message: 'Defendant information cannot be empty.',
    }
  }

  if (
    !data ||
    !data[0].first_name ||
    !data[0].last_name ||
    !data[0].phone ||
    !data[0].email
  ) {
    return {
      $valid: false,
      $message: 'At least one Defendant is needed.',
    }
  }

  return {
    $valid: true,
    $message: '',
  }
}
