<template>
  <div>
    <div :class="isFormLoading ? 'loader' : 'not-loader'">
      <pulse-loader
        :loading="isFormLoading"
        :color="loaderColor"
      ></pulse-loader>
    </div>
    <div class="center">
      <p v-if="this.customerExists == false" class="alert customer-not-found">
        CUSTOMER NOT FOUND
      </p>
      <div v-if="this.customerExists" class="content">
        <h1>Case Information</h1>
        <form class="container" encType="multipart/form-data">
          <div class="section">
            <h3>Upload files</h3>
            <el-checkbox
              v-model="formFields.affidavitIncludedOnTerminationNotice"
              label="Affidavit is already on the termination notice"
            />
            <div class="uploads">
              <el-form-item>
                <div class="upload-demo">
                  <input
                    v-on:change="uploadFileAction"
                    type="file"
                    name="terminationNotice"
                    id="terminationNotice"
                  />
                  <el-button type="primary"
                    ><label for="terminationNotice">Upload file</label>
                  </el-button>
                  <p>
                    Termination Notice
                    <span class="important">*</span>
                  </p>
                  <img
                    v-if="formFields.terminationNotice"
                    src="@/assets/icons/file.png"
                    style="max-width: 25px"
                  />
                  <p v-if="v$.terminationNotice.$error" class="alert">
                    {{ v$.terminationNotice.validateFile.$response.$message }}
                  </p>
                </div>
              </el-form-item>
              <el-form-item>
                <div
                  v-if="!formFields.affidavitIncludedOnTerminationNotice"
                  class="upload-demo"
                >
                  <input
                    v-on:change="uploadFileAction"
                    type="file"
                    name="affidavitOfService"
                    id="affidavitOfService"
                  />
                  <el-button type="primary"
                    ><label for="affidavitOfService"
                      >Upload file</label
                    ></el-button
                  >
                  <p>
                    Affidavit of Service
                    <span class="important">*</span>
                  </p>
                  <img
                    v-if="formFields.affidavitOfService"
                    src="@/assets/icons/file.png"
                    style="max-width: 25px"
                  />
                  <p v-if="v$.affidavitOfService.$error" class="alert">
                    {{ v$.affidavitOfService.validateFile.$response.$message }}
                  </p>
                </div>
              </el-form-item>
              <el-form-item v-if="formFields.caseType != 'squatter'">
                <div class="upload-demo">
                  <input
                    v-on:change="uploadFileAction"
                    type="file"
                    name="tenantLedger"
                    id="tenantLedger"
                  />
                  <el-button type="primary"
                    ><label for="tenantLedger">Upload file</label></el-button
                  >
                  <p>
                    Tenant Ledger
                    <span class="important">*</span>
                  </p>
                  <img
                    v-if="formFields.tenantLedger"
                    src="@/assets/icons/file.png"
                    style="max-width: 25px"
                  />
                  <p v-if="v$.tenantLedger.$error" class="alert">
                    {{ v$.tenantLedger.validateFile.$response.$message }}
                  </p>
                </div>
              </el-form-item>
              <el-form-item
                v-if="formFields.caseType != 'lease-violation'"
              ></el-form-item>
              <el-form-item v-if="formFields.caseType == 'lease-violation'">
                <div class="upload-demo">
                  <input
                    v-on:change="uploadFileAction"
                    type="file"
                    name="tenantLease"
                    id="tenantLease"
                  />
                  <el-button type="primary"
                    ><label for="tenantLease">Upload file</label></el-button
                  >
                  <p>
                    Tenant Lease
                    <span class="important"></span>
                  </p>
                  <img
                    v-if="formFields.tenantLease"
                    src="@/assets/icons/file.png"
                    style="max-width: 25px"
                  />
                  <p>
                    * Only needed for Commercial and "Lease Violation" matters
                  </p>
                  <p v-if="v$.tenantLease.$error" class="alert">
                    {{ v$.tenantLease.validateFile.$response.$message }}
                  </p>
                </div>
              </el-form-item>
            </div>
            <p class="alert_info">
              PLEASE REMEMBER: <br />
              DO NOT CONTINUE IF YOUR NOTICE HAS NOT YET EXPIRED <br />
              DO NOT CONTINUE IF YOUR NOTICE IS MISSING DATES OR SIGNATURES
            </p>
          </div>
          <div class="section">
            <h3>Name of parties</h3>
            <div class="field" id="plaintiff">
              <el-form-item>
                <p>
                  Plaintiff Name &nbsp;
                  <span class="clarification">
                    (What entity is filling this case)
                  </span>
                </p>
                <el-input v-model="formFields.plaintiffName" />
                <p v-if="v$.plaintiffName.$error" class="alert">
                  {{ v$.plaintiffName.plaintiffValidator.$response.$message }}
                </p>
              </el-form-item>
            </div>
            <h1>V.</h1>
            <div class="defendants">
              <el-form-item
                v-for="(defendant, index) in formFields.defendants"
                :key="defendant.key"
              >
                <div :class="'field defendant'" style="width: 360px">
                  <el-input
                    placeholder="FIRST NAME"
                    v-model="defendant.first_name"
                    style="width: 245px"
                  />
                  &nbsp;&nbsp;
                  <el-input
                    placeholder="LAST NAME"
                    v-model="defendant.last_name"
                    style="width: 245px"
                  />
                </div>
                <div :class="'field defendant'">
                  <el-input
                    v-model="defendant.phone"
                    placeholder="PHONE"
                    v-mask="['(###) ###-####']"
                  />
                </div>
                <div :class="'field defendant'">
                  <el-input v-model="defendant.email" placeholder="EMAIL" />
                </div>
                <el-button
                  type="danger"
                  v-on:click="removeDefendant(index)"
                  :disabled="index <= 0"
                >
                  X
                </el-button>
              </el-form-item>
              <p v-if="v$.defendants.$error" class="alert">
                {{ v$.defendants.defendantsValidator.$response.$message }}
              </p>
              <el-button
                type="primary"
                class="mt-2"
                v-on:click="addNewDefendant()"
              >
                Add Defendant
              </el-button>
            </div>
            <div class="field all-uknown">
              <el-form-item>
                <p>All unknown occupants</p>
                <el-input v-model="formFields.allUnknownOccupants" />
                <p v-if="v$.allUnknownOccupants.$error" class="alert">
                  {{
                    v$.allUnknownOccupants.allUnknownOccupantsValidator
                      .$response.$message
                  }}
                </p>
              </el-form-item>
            </div>
          </div>
          <div class="section">
            <h3>Address of defendant</h3>
            <div class="field medium nowrap" id="street">
              <el-form-item>
                <p>Address</p>
                <input
                  id="autocomplete"
                  type="text"
                  v-model="formFields.street"
                  placeholder=""
                />
                <!-- <el-input id="autocomplete" v-model="formFields.street" /> -->
              </el-form-item>
              <p v-if="v$.street.$error" class="alert">
                {{ v$.street.streetValidator.$response.$message }}
              </p>
            </div>
            <div v-if="!formFields.noUnitAddress" class="field medium nowrap">
              <el-form-item>
                <p>Unit/Suite</p>
                <el-input v-model="formFields.unit" />
              </el-form-item>
            </div>
            <el-checkbox
              label="There is no unit/suite Number (this is usually checked for commercial or single family homes)"
              v-model="formFields.noUnitAddress"
            />
            <p class="alert_info">
              THE ADDRESS ON THE NOTICE MUST BE PERFECT. IF THERE IS A TYPO OR
              ANY MISSING INFO RELATED TO THE ADDRESS OR IF IT DOESN'T MATCH THE
              PHYSICAL ADDRESS AT THE PROPERTY, THE CASE COULD GET DISMISSED.
            </p>
          </div>
          <div class="section" v-if="formFields.caseType != 'squatter'">
            <h3>Rent and Balance</h3>
            <div class="field date">
              <el-form-item id="total">
                <p>Total Balance</p>
                <span class="clarification">
                  (If subsidized only include tenant balance)
                </span>
                <el-input v-model="formFields.totalBalance" />
                <p v-if="v$.totalBalance.$error" class="alert">
                  {{ v$.totalBalance.totalBalanceValidator.$response.$message }}
                </p>
              </el-form-item>
              <el-form-item id="through">
                <p style="min-width: 210px">Balance Through</p>
                <span class="clarification"> (Select Year & Month) </span>
                <el-date-picker
                  v-model="formFields.balanceThrough"
                  type="month"
                  placeholder="Pick Month and Year"
                />
                <p v-if="v$.balanceThrough.$error" class="alert">
                  {{
                    v$.balanceThrough.balanceThroughValidator.$response.$message
                  }}
                </p>
              </el-form-item>
            </div>
            <div class="field" id="monthly">
              <el-form-item>
                <p style="min-width: 200px">Monthly Rent</p>
                <span class="clarification">
                  (If subsidized, only include tenant rent portion)(Include
                  parking, monthly fees, etc)
                </span>
                <el-input
                  v-model="formFields.monthlyRent"
                  style="max-width: 345px"
                />
                <p v-if="v$.monthlyRent.$error" class="alert">
                  {{ v$.monthlyRent.monthlyRentValidator.$response.$message }}
                </p>
              </el-form-item>
            </div>
          </div>
          <div class="section">
            <h3>Final Questions</h3>
            <div class="field final-questions" id="subsidized">
              <p>If tenant subsidized, select type:</p>
              <el-form-item>
                <el-select
                  v-model="formFields.tenantSubsidized"
                  class="m-2"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in tenantOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <p v-if="v$.tenantSubsidized.$error" class="alert">
                  {{
                    v$.tenantSubsidized.tenantSubsidizedValidator.$response
                      .$message
                  }}
                </p>
              </el-form-item>
            </div>
            <div class="field final-questions" id="tenant">
              <p>Can we put tenant on a court ordered payment plan</p>
              <el-form-item>
                <el-select
                  v-model="formFields.tenantOnCourt"
                  class="m-2"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in tenantOnCourtOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <p v-if="v$.tenantOnCourt.$error" class="alert">
                  {{
                    v$.tenantOnCourt.tenantOnCourtValidator.$response.$message
                  }}
                </p>
              </el-form-item>
            </div>
            <div class="field final-questions" id="maxLength">
              <p>
                If court payment plan allowed, what is the max length you will
                allow. Recommendation: If balance &lt;$5K, allow up to 12 mo., >
                5K, allow 12-24 months.
              </p>
              <el-form-item>
                <el-select
                  v-model="formFields.maxLength"
                  class="m-2"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in maxLengthOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <p v-if="v$.maxLength.$error" class="alert">
                  {{ v$.maxLength.maxLengthValidator.$response.$message }}
                </p>
              </el-form-item>
            </div>
            <div class="field final-questions" id="waive">
              <p>
                What amount can we waive if we get them out within 30-60 days
                after their first court date?
              </p>
              <el-form-item>
                <el-select
                  v-model="formFields.amountWaive"
                  class="m-2"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in waiveOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <p v-if="v$.amountWaive.$error" class="alert">
                  {{ v$.amountWaive.amountWaiveValidator.$response.$message }}
                </p>
              </el-form-item>
            </div>
            <div class="field final-questions">
              <p>Anything unique you want us to know about your case?</p>
              <el-form-item>
                <el-input v-model="formFields.uniqueInformation" />
                <p v-if="v$.uniqueInformation.$error" class="alert">
                  {{
                    v$.uniqueInformation.uniqueInformationValidator.$response
                      .$message
                  }}
                </p>
              </el-form-item>
            </div>
            <p class="alert_info">
              IF THIS SECTION IS NOT FILLED OUT, you are giving authority to our
              team to do the best we can to settle the matter. <br /><br />
            </p>
          </div>
          <p class="alert" v-if="formFields.showFinalError">
            There are issues with your submission. Please review the items
            highlighted in red.&nbsp;&nbsp;
          </p>

          <el-button type="primary" @click="submitForm">Submit</el-button>
          <div v-if="this.submittingErrors.length">
            <p ref="algo" class="alert">{{ this.submittingErrors[0] }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core'
  import { computed, reactive } from 'vue'
  import {
    addNewForm,
    getCustomer,
    setGooglePlaces,
  } from '../services/CloudFunctions'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import {
    defendantsValidator,
    plaintiffValidator,
    allUnknownOccupantsValidator,
    streetValidator,
    totalBalanceValidator,
    balanceThroughValidator,
    monthlyRentValidator,
    tenantSubsidizedValidator,
    tenantOnCourtValidator,
    maxLengthValidator,
    amountWaiveValidator,
    uniqueInformationValidator,
    validateFile,
  } from '../validations/index'
  import { useRouter } from 'vue-router'
  import { mask } from 'vue-the-mask'

  export default {
    name: 'Case',
    directives: { mask },
    components: {
      PulseLoader,
    },
    beforeCreate() {
      var url = location.pathname.split('/')
      var customer = url[1]
      var caseType = url[2]
      var caseTypes = [
        'non-payment',
        'lease-violation',
        'squatter',
        'non-renewal',
      ]
      var router = useRouter()
      if (!caseTypes.includes(caseType)) router.push(`/${customer}`)
    },
    data() {
      return {
        isFormLoading: true,
        router: useRouter(),
        submittingErrors: [],
        fileError: false,
        customer: location.pathname.split('/')[1],
        customerExists: null,
      }
    },
    mounted() {
      var $this = this
      getCustomer(this.customer).then(function (response) {
        $this.isFormLoading = false
        if (!response.data) return ($this.customerExists = false)
        setTimeout(() => {
          var input = document.getElementById('autocomplete')
          if (!input) return
          const gp = setGooglePlaces(input)
          gp.addListener('place_changed', () => {
            $this.setAddress(gp.getPlace())
          })
        }, 3000)
        return ($this.customerExists = true)
      })
    },
    methods: {
      submitForm() {
        var $this = this
        $this.isFormLoading = true
        this.submit().then(function (res) {
          if (!res) $this.isFormLoading = false
          var response = res.response
          if (response && response.status.toString()[0] != '2') {
            $this.isFormLoading = false
            $this.submittingErrors.push(response.data.errorMessage)
          }
          if (!response) {
            $this.isFormLoading = false
            $this.router.push(`/${$this.customer}/thank-you`)
          }
        })
      },
    },
    setup() {
      const formFields = reactive({
        caseType: location.pathname.split('/')[2],
        defendants: [
          {
            key: 1,
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
          },
        ],
        terminationNotice: false,
        affidavitOfService: false,
        tenantLedger: false,
        tenantLease: false,
        allUnknownOccupants: 'All unknown occupants',
        plaintiffName: '',
        street: '',
        unit: '',
        noUnitAddress: false,
        affidavitIncludedOnTerminationNotice: false,
        totalBalance: '',
        balanceThrough: '',
        monthlyRent: '',
        tenantSubsidized: '',
        tenantOnCourt: '',
        maxLength: '',
        amountWaive: '',
        uniqueInformation: '',
        isFormLoading: true,
        showFinalError: false,
      })

      var terminationNotice = {}
      var affidavitOfService = {}
      var tenantLedger = {}
      var tenantLease = {}

      const rules = computed(() => {
        return {
          terminationNotice: { validateFile },
          tenantLedger:
            formFields.caseType != 'squatter' ? { validateFile } : {},
          affidavitOfService: !formFields.affidavitIncludedOnTerminationNotice
            ? { validateFile }
            : {},
          tenantLease:
            formFields.caseType == 'lease-violation' ? { validateFile } : {},
          plaintiffName: { plaintiffValidator },
          defendants: { defendantsValidator },
          allUnknownOccupants: { allUnknownOccupantsValidator },
          street: { streetValidator },
          totalBalance:
            formFields.caseType != 'squatter' ? { totalBalanceValidator } : {},
          balanceThrough:
            formFields.caseType != 'squatter'
              ? { balanceThroughValidator }
              : {},
          monthlyRent:
            formFields.caseType != 'squatter' ? { monthlyRentValidator } : {},
          tenantSubsidized: { tenantSubsidizedValidator },
          tenantOnCourt: { tenantOnCourtValidator },
          maxLength: { maxLengthValidator },
          amountWaive: { amountWaiveValidator },
          uniqueInformation: { uniqueInformationValidator },
        }
      })

      const v$ = useVuelidate(rules, formFields)

      const tenantOptions = ['', 'Tax Credit', 'CHA', 'HACC', 'Other']
      const tenantOnCourtOptions = ['', 'YES', 'NO']
      const maxLengthOptions = ['', '24', '12', 'NOT APPLICABLE']
      const waiveOptions = ['', '100%', '50%', '25%', '0%']

      const addNewDefendant = () => {
        var index = formFields.defendants.length
        formFields.defendants.push({
          key: index + 1,
          name: '',
          phone: '',
          email: '',
        })
      }

      const removeDefendant = (index) => {
        formFields.defendants.splice(index, 1)
      }

      const finalquestion = ''

      const submit = async () => {
        v$.value.$validate()
        if (!v$.value.$error) {
          var customer = await getCustomer(location.pathname.split('/')[1])
          formFields.customer = customer.data
          var data = {
            form: formFields,
            files: [
              terminationNotice,
              affidavitOfService,
              tenantLedger,
              tenantLease,
            ],
          }
          return addNewForm(data)
        } else {
          formFields.showFinalError = true
          return console.log('result', v$.value.$errors)
        }
      }

      const uploadFileAction = async (data) => {
        const rawFile = data.target.files[0]
        var fileReader = new FileReader()
        fileReader.readAsArrayBuffer(rawFile)
        switch (data.target.id) {
          case 'terminationNotice':
            formFields.terminationNotice = true
            fileReader.onload = function () {
              terminationNotice.fileContent = new Buffer(
                new Uint8Array(fileReader.result)
              )
            }
            terminationNotice = {
              type: data.target.id,
              fileTermination: rawFile.name.split('.')[1],
            }
            break
          case 'affidavitOfService':
            formFields.affidavitOfService = true
            fileReader.onload = function () {
              affidavitOfService.fileContent = new Buffer(
                new Uint8Array(fileReader.result)
              )
            }
            affidavitOfService = {
              type: data.target.id,
              fileTermination: rawFile.name.split('.')[1],
            }
            break
          case 'tenantLedger':
            formFields.tenantLedger = true
            fileReader.onload = function () {
              tenantLedger.fileContent = new Buffer(
                new Uint8Array(fileReader.result)
              )
            }
            tenantLedger = {
              type: data.target.id,
              fileTermination: rawFile.name.split('.')[1],
            }
            break
          case 'tenantLease':
            formFields.tenantLease = true
            fileReader.onload = function () {
              tenantLease.fileContent = new Buffer(
                new Uint8Array(fileReader.result)
              )
            }
            tenantLease = {
              type: data.target.id,
              fileTermination: rawFile.name.split('.')[1],
            }
            break
        }
      }

      const setAddress = async (place) => {
        formFields.street = place.formatted_address
      }

      const loaderColor = '#409eff'

      return {
        finalquestion,
        submit,
        addNewDefendant,
        uploadFileAction,
        removeDefendant,
        terminationNotice,
        affidavitOfService,
        tenantLedger,
        tenantLease,
        tenantOnCourtOptions,
        tenantOptions,
        loaderColor,
        maxLengthOptions,
        waiveOptions,
        v$,
        formFields,
        setAddress,
      }
    },
  }
</script>

<style scoped>
  #autocomplete {
    width: 100%;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    height: 32px;
    border-radius: 8px;
    border: none;
    color: #606266;
  }
  .defendant {
    width: 219px;
  }

  .customer-not-found {
    text-align: center;
    font-size: 20px;
    margin: 0 0 15px;
  }
  .inactive {
    display: none;
  }
  div.defendants {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .el-form-item__content {
    margin-left: 0 !important;
  }

  div.uploads {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 10px;
  }

  div.content {
    padding: 20px 0 200px;
  }

  h1 {
    margin: 0 0 15px;
    text-transform: uppercase;
    font-family: 42px;
  }
  .container {
    max-width: 850px;
    box-sizing: border-box;
    margin: 0 auto 100px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .alert_info {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    color: rgb(15, 17, 121);
  }
</style>
