module.exports = function balanceThroughValidator(data) {
  if (!data) {
    return {
      $valid: false,
      $message: 'This field is required!',
      extraParams: {},
    }
  }
  return {
    $valid: true,
    $message: '',
  }
}
